<template>
  <form
    :class="$style.form"
    @reset.prevent="handleReset"
    @submit.prevent="handleSubmit"
  >
    <Checkbox :class="$style.input" v-model="form.isIndividualEntrepreneur">
      Индивидуальный предприниматель
    </Checkbox>
    <Input
      :class="$style.input"
      v-model="form.name"
      :error="errors.name"
      placeholder="Название компании*"
    />
    <Input
      :class="$style.input"
      v-model="form.legalAddress"
      :error="errors.legalAddress"
      placeholder="Юридический адрес"
    />
    <Input
      :class="$style.input"
      v-model="form.unp"
      :error="errors.unp"
      placeholder="ИНН/ЕГРПОУ/УНП/БИН*"
    />
    <div :class="$style.input">
      <Button :disabled="!form.unp" @click="updateByINN">
        заполнить по ИНН
      </Button>
      <div v-if="errorUpdateByINN" :class="$style.error">
        {{ errorUpdateByINN }}
      </div>
    </div>
    <Input
      :class="$style.input"
      v-model="form.kpp"
      :error="errors.kpp"
      placeholder="КПП"
    />
    <Input
      :class="$style.input"
      v-model="form.kppop"
      :error="errors.kppop"
      placeholder="КПП ОП"
    />
    <Input
      :class="$style.input"
      v-model="form.ogrn"
      :error="errors.ogrn"
      placeholder="ОГРН/ОГРНИП"
    />
    <Input
      :class="$style.input"
      v-model="form.paymentAccount"
      :error="errors.paymentAccount"
      placeholder="Расчетный счет*"
    />
    <Input
      :class="$style.input"
      v-model="form.bankName"
      :error="errors.bankName"
      placeholder="Наименование банка*"
    />
    <Input
      :class="$style.input"
      v-model="form.bik"
      :error="errors.bik"
      placeholder="БИК*"
    />
    <Input
      :class="$style.input"
      v-model="form.corAccount"
      :error="errors.corAccount"
      placeholder="Кор счет*"
    />
    <h5>Ответственное/контактное лицо</h5>
    <br />
    <Input
      :class="$style.input"
      v-model="form.responsiblePerson"
      :error="errors.responsiblePerson"
      placeholder="ФИО*"
    />
    <Input
      :class="$style.input"
      v-model="form.phone"
      :error="errors.phone"
      placeholder="Телефон*"
    />
    <Input
      :class="$style.input"
      v-model="form.email"
      :error="errors.email"
      placeholder="Email*"
    />
    <Checkbox :class="$style.input" v-model="form.isVerified">
      Прошел модерацию
    </Checkbox>
    <div :class="$style.actions">
      <Button type="submit">Сохранить</Button>
      <Button type="reset" color="outline-secondary">Назад</Button>
    </div>
  </form>
</template>

<script>
import delivery from '@/delivery'
import Input from '@/components/atoms/Input.vue'
import Button from '@/components/atoms/Button.vue'
import Checkbox from '@/components/atoms/Checkbox.vue'

export default {
  components: {
    Input,
    Button,
    Checkbox,
  },
  async mounted() {
    const { value } =
      await delivery.AddwineCore.ClientRequesitesActions.getById(
        this.$route.params.id,
      )
    delete value.ID
    this.form = value
  },
  data() {
    return {
      errorUpdateByINN: '',
      form: {
        isIndividualEntrepreneur: false,
        name: '',
        legalAddress: '',
        unp: '',
        kpp: '',
        kppop: '',
        ogrn: '',
        paymentAccount: '',
        bankName: '',
        bik: '',
        corAccount: '',
        responsiblePerson: '',
        phone: '',
        email: '',
        isVerified: false,
      },
      errors: {
        name: '',
        legalAddress: '',
        unp: '',
        kpp: '',
        kppop: '',
        ogrn: '',
        paymentAccount: '',
        bankName: '',
        bik: '',
        corAccount: '',
        responsiblePerson: '',
        phone: '',
        email: '',
      },
    }
  },
  methods: {
    clearErros() {
      for (const key in this.errors) {
        this.errors[key] = ''
      }
    },
    isFormValid() {
      let isValid = true

      if (!this.form.name) {
        isValid = false
        this.errors.name = 'заполните это поле'
      }
      if (!this.form.unp) {
        isValid = false
        this.errors.unp = 'заполните это поле'
      }
      if (!this.form.bankName) {
        isValid = false
        this.errors.bankName = 'заполните это поле'
      }
      if (!this.form.bik) {
        isValid = false
        this.errors.bik = 'заполните это поле'
      }
      if (!this.form.corAccount) {
        isValid = false
        this.errors.corAccount = 'заполните это поле'
      }
      if (!this.form.paymentAccount) {
        isValid = false
        this.errors.paymentAccount = 'заполните это поле'
      }
      if (!this.form.responsiblePerson) {
        isValid = false
        this.errors.responsiblePerson = 'заполните это поле'
      }
      if (!this.form.phone) {
        isValid = false
        this.errors.phone = 'заполните это поле'
      } else if (
        !/^\+?[\d]{3}[\d]{6,12}$/.test(this.form.phone.replace(/[-() ]/g, ''))
      ) {
        isValid = false
        this.errors.phone = 'некорректный номер телефона'
      }
      if (!this.form.email) {
        isValid = false
        this.errors.email = 'заполните это поле'
      } else if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.form.email,
        )
      ) {
        isValid = false
        this.errors.email = 'некорректный e-mail'
      }
      return isValid
    },
    handleReset() {
      this.$router.go(-1)
      this.clearErros()
    },
    async handleSubmit() {
      this.clearErros()
      if (this.isFormValid()) {
        const { error } =
          await delivery.AddwineCore.ClientRequesitesActions.update(
            this.form.id,
            this.form,
          )
        if (!error) {
          this.$router.go(-1)
        }
      }
    },
    async updateByINN() {
      const result =
        await delivery.AddwineCore.ClientRequesitesActions.getByDaData(
          this.form.unp,
        )
      if (result.error) {
        this.errorUpdateByINN = result.error
      }
      if (!result.value.length) {
        this.errorUpdateByINN = 'К сожалению, ничего не найдено'
      } else {
        this.errorUpdateByINN = ''
        this.form.name = result.value[0].name
        this.form.kpp = result.value[0].kpp
        this.form.unp = result.value[0].unp
        this.form.ogrn = result.value[0].ogrn
        this.form.legalAddress = result.value[0].legalAddress
        this.form.isIndividualEntrepreneur =
          result.value[0].isIndividualEntrepreneur
      }
    },
  },
}
</script>

<style lang="scss" module>
.form {
  margin: auto;
  max-width: 24rem;
  padding: 2rem 0;
  .input {
    margin-bottom: 1.5rem;
    .error {
      @include errorInscription;
      text-align: left;
      padding-top: 0.5rem;
    }
  }
  .actions {
    display: flex;
    [type='submit'] {
      flex: 1 1 auto;
      margin-right: 1.5rem;
    }
  }
}
</style>
