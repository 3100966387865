<template>
  <div
    :class="[
      $style.checkbox,
      { [$style.checked]: checked, [$style.disabled]: disabled }
    ]"
    role="checkbox"
    @click="$emit('change', !checked)"
  >
    <div :class="$style.indicator">
      <div></div>
    </div>
    <div :class="$style.label">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors';

.checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  .indicator {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.375rem;
    border: 0.063rem solid $light-gray;
    > div {
      width: 0.625rem;
      height: 0.625rem;
      background: $dark-orange;
      border-radius: 0.188rem;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }
  }
  .label {
    margin-left: 0.5rem;
  }
  &:hover {
    .indicator {
      border-color: $dark-orange;
    }
  }
  &.checked {
    .indicator {
      border-width: 0.125rem;
      border-color: $dark-orange;
      > div {
        opacity: 1;
      }
    }
  }
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}
</style>
